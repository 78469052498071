import React from 'react';
import LoadExternalScript from "../utils/load-external-script";
const writeView = 'https://www.kerfuffle.com/media/stb/template/js/review-widget.js?view=embed&obj=content&id=28&t=1619006358108&sd=1';

const IframeReview = (props) => {
    return (
      <>
      </>
    );
}

export default IframeReview
