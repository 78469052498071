/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, image: metaImage, title }) {
  	const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
			author
			siteURL
          }
        }
      }
    `
  	)

  const metaDescription = description || site.siteMetadata.description
  const image =
  metaImage && metaImage.src
	? `${site.siteMetadata.siteURL}${metaImage.src}`
	: null

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
			name: `description`,
			content: metaDescription,
		},
        {
			property: `og:title`,
			content: `${title} | Crux Careers`,
        },
        {
			property: `og:description`,
			content: metaDescription,
        },
        {
			property: `og:type`,
			content: `website`,
        },
        {
			name: `twitter:creator`,
			content: site.siteMetadata.author,
        },
        {
			name: `twitter:title`,
			content: `${title} | Crux Careers`,
        },
        {
			name: `twitter:description`,
			content: metaDescription,
		},
		{
			name: `twitter:site`,
			content: site.siteMetadata.author,
		},		
		// {
		// 	name: `google-site-verification`,
		// 	content: `H3x6Su1zsI0ak8UO5QenSARRHgq3a8Wdsg5G-kn-zLg`,
		// },
	  ]
	  .concat(
		metaImage
		  ? [
			  {
				property: "og:image",
				content: image,
			  },
			  {
				property: "og:image:width",
				content: metaImage.width,
			  },
			  {
				property: "og:image:height",
				content: metaImage.height,
			  },
			  {
				name: "twitter:card",
				content: "summary_large_image",
			  },
			  {
				name: "twitter:image",
				content: image,
			  },
			  {
				itemprop: `image`,
				content: image,
			  },
			]
		  : [
			  {
				name: "twitter:card",
				content: "summary",
			  },
			]
	  )
	  .concat(meta)}
    />
  )
}

SEO.defaultProps = {
	lang: `en`,
	meta: [],
	description: ``,
}

SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string.isRequired,
	image: PropTypes.shape({
		src: PropTypes.string.isRequired,
		height: PropTypes.number,
		width: PropTypes.number,
	}),
}

export default SEO
