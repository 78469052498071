import React from 'react';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';

const FooterColLinks = (props) => {
    return (
        <Card.Body>
            <ul className="list-inline footer-menu__list">
            {props.linksArray.map((item, index) => (
                <li key={item.id}><a href={item.linkUrl} className={props.linkClass}>{item.linkTitle}</a></li>
            ))}
            </ul>
        </Card.Body>
    );
}

// Check all the prop types
FooterColLinks.propTypes = {
    linksArray: PropTypes.array
};

export default FooterColLinks;