import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

// animations
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { animSetting } from "@hooks/animations";

import  "./assets/_index.scss";

import FooterColHeader from './FooterColHeader';
import FooterColLinks from './FooterColLinks';
import FooterSocialLinks from './FooterSocialLinks';
import FooterCopyright from './FooterCopyright';
import IframeReview from './IframeBlock';
import { prototype } from 'react-markdown';
// Force plugin to not get dropped during build
gsap.registerPlugin(ScrollTrigger);

const Footer = (props) => {
    const [activeKey, setActiveKey] = useState("");

    let contAnim = useRef(null);
    let linkAnim = useRef(null);
    let socialAnim = useRef(null);

    useEffect(() => {
        const tl = gsap.timeline({
            defaults: {
                duration: animSetting.duration,
                ease: animSetting.ease,
            },
            scrollTrigger: {
                trigger: contAnim,
                start: 'top 80%',
                end: 'bottom bottom',
                toggleActions: 'play none none reverse',
                ease: animSetting.ease
            }
        });
        tl.to(contAnim, {
            duration: 0,
            css: {visibility: 'visible'}
        })
        .from([linkAnim, socialAnim], {
            opacity: 0,
            y: 70,
            duration: 1.5,
            stagger: 0.6
        })
    }, [])

    return (
        <footer className={`footer ${props.sectionClass}`} ref={el => contAnim = el}>
            <Container>
                <Row ref={el => linkAnim = el}>
                    {props.accordionData.map((item, index) => (
                        <Accordion key={item.id} defaultActiveKey="" activeKey={activeKey} className={props.accordionClass}>
                            <Card key={item.id}>
                                <FooterColHeader
                                    index={item.id}
                                    toggleName={item.toggleName}
                                    activeKey={activeKey}
                                    setActiveKey={setActiveKey}
                                    toggleSpacing={props.toggleSpacing}
                                />

                                <Accordion.Collapse eventKey={item.id}>
                                    <FooterColLinks
                                        linkClass={props.linkClass}
                                        linksArray={item.linksArray}
                                    />
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    ))}
                </Row>
                <Row className={props.bottomFooterClass} ref={el => socialAnim = el}>
                    <FooterSocialLinks
                        socialClass={props.socialClass}
                        socialUlClass={props.socialUlClass}
                    />
                    <IframeReview 
                    reviewClass={props.reviewClass}
                    />
                    <FooterCopyright
                        copyrightClass={props.copyrightClass}
                    />
                </Row>
            </Container>
        </footer>
    );
}

// Check all the prop types
Footer.propTypes = {
    sectionClass: PropTypes.string,
    toggleSpacing: PropTypes.string,
    linkClass: PropTypes.string,
    accordionClass: PropTypes.string,
    bottomFooterClass: PropTypes.string,
    socialClass: PropTypes.string,
    copyrightClass: PropTypes.string,
    accordionData: PropTypes.array,
    reviewClass: PropTypes.string
};

// Specifies the default values for props:
Footer.defaultProps = {
    sectionClass: ' section section-grey-bg pt-35 pt-md-55 pt-xl-92',
    toggleSpacing: ' mb-16 font-weight-bold no-underline',
    linkClass: 'footer__menu__link',
    accordionClass: 'col-12 col-md-6 col-xl-3 mb-md-44 mb-xl-62',
    bottomFooterClass: 'footer__bottom d-flex d-flex-column flex-xl-row mt-20 mt-md-0',
    socialClass: 'footer__social col-12 col-xl-4 order-xl-2',
    socialUlClass: ' text-xl-right mb-31 mb-xl-0',
    copyrightClass: 'footer__copyright col-12 col-xl-5 order-xl-0',
    reviewClass: 'col-12 col-xl-3 order-xl-1',
    accordionData: [
        {
            id: 1,
            toggleName: 'For Candidates',
            linksArray: [
                {
                    id: 1,
                    linkTitle: 'Register as a Candidate',
                    linkUrl: '/candidates/upload-your-cv/'
                },
                {
                    id: 2,
                    linkTitle: 'Job Search',
                    linkUrl: '/job-search'
                },
                {
                    id: 3,
                    linkTitle: 'Refer a Candidate',
                    linkUrl: '/candidates/refer-a-candidate/'
                },
                // {
                //     id: 4,
                //     linkTitle: 'Training',
                //     linkUrl: '/'
                // }
            ]
        },
        {
            id: 2,
            toggleName: 'For Employers',
            linksArray: [
                {
                    id: 1,
                    linkTitle: 'Register as an Employer',
                    linkUrl: '/employers/register/'
                },
                {
                    id: 2,
                    linkTitle: 'Post a Job',
                    linkUrl: '/employers/post-jobs/'
                },
                {
                    id: 3,
                    linkTitle: 'Subscription Plans',
                    linkUrl: '/employers/subscription-plans/'
                },
                {
                    id: 4,
                    linkTitle: 'Mentoring',
                    linkUrl: '/employers/mentoring-and-training/'
                },
                {
                    id: 5,
                    linkTitle: 'Refer an Employer',
                    linkUrl: '/employers/refer-an-employer/'
                }
            ]
        },
        {
            id: 3,
            toggleName: 'About Crux Careers',
            linksArray: [
                {
                    id: 1,
                    linkTitle: 'Why Crux',
                    linkUrl: '/about/why-crux-careers/'
                },
                {
                    id: 2,
                    linkTitle: 'Our Clients',
                    linkUrl: '/about/our-clients/'
                },
                {
                    id: 3,
                    linkTitle: 'Our People',
                    linkUrl: '/about/our-people/'
                },
                {
                    id: 4,
                    linkTitle: 'Customer Reviews',
                    linkUrl: '/about/reviews/'
                }
            ]
        },
        {
            id: 4,
            toggleName: 'Help & Advice',
            linksArray: [
                // {
                //     id: 1,
                //     linkTitle: 'Blog',
                //     linkUrl: '/help-and-advice/blogs/'
                // },
                // {
                //     id: 2,
                //     linkTitle: 'Industry News',
                //     linkUrl: '/help-and-advice/industry-news/'
                // },
                {
                    id: 3,
                    linkTitle: 'Employer Resources',
                    linkUrl: '/help-and-advice/employer-guide/'
                },
                {
                    id: 4,
                    linkTitle: 'Candidate Resources',
                    linkUrl: '/help-and-advice/candidate-guide/'
                }
            ]
        }
    ]
}

export default Footer