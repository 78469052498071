/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import CookieConsent from "react-cookie-consent"
import { Link } from "gatsby"

const Layout = ({ children }) => {
    return (
        <>
            <div className="site-wrapper">
                <main>{children}</main>
            </div>
            <CookieConsent
                disableStyles={true}
                buttonClasses="btn btn-link gotit"
                containerClasses="cookie-popup d-lg-flex d-block"
                contentClasses=""
                buttonText="Accept Cookies"
            >
                <p className="mb-0 text-center">By continuing, you agree to our <Link to="/privacy-policy">Privacy policy</Link>.</p>
            </CookieConsent>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
