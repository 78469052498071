import React, { useState, useEffect, useRef } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Button from "react-bootstrap/Button";
// animations
import { gsap } from "gsap";
import { animSetting } from "@hooks/animations";

import  "./assets/scss/_header.scss";

import Icon from '@components/icon/icon';
import LogoIcon from '@icons/logo.inline.svg';
import LogoWhiteIcon from '@icons/logo-white.inline.svg';
import SearchIcon from '@icons/search.inline.svg';
// import UserIcon from '@icons/user.inline.svg';
import Navigation from '@components/navigation/navigation';
import NavLinks from '@components/header/header_nav_links.js';

export const useScrollHandler = () => {
    const [scrollDir, setScrollDir] = useState(true);
    const [scrollPos, setScrollPos] = useState(false);

    // running on mount
    useEffect(() => {
        // So I just defined a threshold point with the initial value of 0 then whenever the scroll goes up or down
        // it will make the new calculation you can increase it if you don't want to immediately calculate new page offset.
        const threshold = 0;
        let lastScrollY = window.pageYOffset;
        let ticking = false;
        
        // In the updateScrollDir function, we will simply check if the threshold is met or not,
        // then if it met I will specify the scroll direction base of the current and previous page offset.
        const updateScrollDir = () => {
            const scrollY = window.pageYOffset;

            if (Math.abs(scrollY - lastScrollY) < threshold) {
                ticking = false;
                return;
            }
            setScrollPos(scrollY > 450 ? true : false);
            setScrollDir(scrollY > 50 && scrollY > lastScrollY ? false : true);
            lastScrollY = scrollY > 0 ? scrollY : 0;
            ticking = false;
        };
        
        // We just used requestAnimationFrame to make sure that we are calculating the new offset
        // after the page got rendered completely after scroll. And then with ticking flag,
        // we will make sure we are just run our event listener callback once in each requestAnimationFrame
        const onScroll = () => {
            if (!ticking) {
                window.requestAnimationFrame(updateScrollDir);
                ticking = true;
            }
        };
      
        window.addEventListener("scroll", onScroll);
      
        return () => window.removeEventListener("scroll", onScroll);
      }, []);
    
    return ([scrollDir,scrollPos])
}

const Header = (props) => {
    const [showHeader, setShowHeader] = useState(false);
    useEffect(() => {
        setShowHeader(true);
    }, [])

    // calling our scroll hook
    const scrollDirection = useScrollHandler()[0];
    const scrollPosition = useScrollHandler()[1];

    const [active, setActive] = useState(false);
    const navigationToggle = () => setActive(!active);

    const headerLinks = useStaticQuery(graphql`
        {
        allStrapiMenuHeaders(filter: {Published: {eq: true}}, sort: {order: ASC, fields: Sort}) {
            edges {
                node {
                    id
                    menuLink {
                    Label
                    page {
                        Slug
                    }
                    targetLink
                    }
                    Parent {
                    id
                    }
                }
                }
            }
        }
    `)

    let contAnim = useRef(null);
    useEffect(() => {
        const tl = gsap.timeline({
            defaults: {
                duration: animSetting.duration,
                ease: animSetting.ease,
            }
        });
        if (contAnim) {
            tl.to(contAnim, {
                duration: 0.8,
                opacity: 1
            })
        }
    }, [])

    return (
        <header className={`header${props.classNameHeader}${scrollDirection ? " header-scroll" : " header-scroll"}${scrollPosition ? " header-white" : ""}${showHeader ? " visible" : ""}`} ref={el => contAnim = el}>
            <div className="d-flex flex-row">
                <div className="header__logo mr-auto">
                    <Link to="/">
                        <Icon>
                            <LogoIcon className="icon-logo" />
                        </Icon>
                        <Icon>
                            <LogoWhiteIcon className="icon-logo-white" />
                        </Icon>
                    </Link>
                </div>
                <div className="header__links">
                    <NavLinks
                        navData={headerLinks.allStrapiMenuHeaders.edges}
                    />
                </div>
                <div className="header__icons d-flex flex-row align-self-center">
                    {/* <Button variant="none" className="header__icons__icon header__icons__account">
                        <Icon classNameIcon="d-none d-sm-none d-md-flex">
                            <UserIcon className="icon-user" />
                        </Icon>
                    </Button> */}
                    {/* <Button variant="none" className="header__icons__icon header__icons__search">
                        <Icon>
                            <SearchIcon className="icon-search" />
                        </Icon>
                    </Button> */}
                    <Link to="/job-search" className="header__icons__icon header__icons__search">
                        <Icon>
                            <SearchIcon className="icon-search" />
                        </Icon>
                    </Link>
                    <Button variant="none" className="header__icons__icon sidebar-trigger">
                        {/* <Icon> */}
                            <Navigation active={active} navigationToggle={navigationToggle} />
                        {/* </Icon> */}
                    </Button>
                    <Link className="btn btn-light" to="/candidates/upload-your-cv">Upload CV</Link>
                </div>
            </div>
        </header>
    )
}

Header.defaultProps = {
  classNameHeader: ``,
}

export default Header
