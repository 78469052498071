import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import NavigationLinkParent from "./navigation_menu_parent"

const NavigationMenus = (props) => {
    
    const createDataTree = dataset => {
        let hashTable = Object.create(null)
        // add a childNodes array to each node
        dataset.forEach( aData => hashTable[aData.node.id] = { ...aData.node, childNodes : [] } )
        let dataTree = []
        // loop over nodes, the child node has to have the Parent id
        dataset.forEach( aData => {
            // push the child node under the parent node
            if( aData.node.Parent ) {
                hashTable[`Menu-headers_${aData.node.Parent.id}`].childNodes.push(hashTable[aData.node.id])
            } else {
                // this is the normal parent node
                dataTree.push(hashTable[aData.node.id])
            }
        })
        
        return dataTree
    }

    const data = useStaticQuery(graphql`
        {
            allStrapiMenuHeaders(filter: {Published: {eq: true}}, sort: {order: ASC, fields: Sort}) {
                edges {
                    node {
                        id
                        menuLink {
                            id
                            Label
                            targetLink
                            page {
                                id
                                Slug
                            }
                        }
                        Parent {
                            id
                            Label
                            menuLink {
                                page {
                                    Slug
                                }
                            }
                        }
                    }
                }
            }
        }
    `)
    return (
        <NavigationLinkParent
            menu={createDataTree(data.allStrapiMenuHeaders.edges)}
            handleExpand={props.handleExpand}
            handleClose={props.handleClose}
        />
    )
}

export default NavigationMenus
